import React, { Component } from 'react';
import logoNoDots from '../img/logo_nodots.svg';

class ModuleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isFrameLoaded: false,
      dashboardURL: null
    }
  }

  componentDidMount() {
    console.log('frame loaded');
  }

  sendToken(token, id) {
    document.getElementById(id).contentWindow.postMessage('token$' + token, '*');
    this.props.updateTitle();
    this.setState({ isFrameLoaded: true });
  }

  render() {
    if (this.props.moduleURL === null) {
      return '';
    }

    return (
      <>
        {!this.state.isFrameLoaded &&
          <div style={{ width: '100%', height: '100%' }}>
            <div className="load_logo">
              <img src={logoNoDots} style={{ width: '170px' }} alt="logo" />
            </div>
            <div className="gooey">
              <span className="dot"></span>
              <div className="dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        }
        <iframe
          title="project"
          allow="camera;"
          allowFullScreen
          src={this.props.moduleURL + '/?mama=1&token=' + this.props.token}
          ref={this.props.frameRef}
          id={this.props.id}
          onLoad={this.sendToken.bind(this, this.props.token, this.props.id)}
        />
      </>
    );
  }
}

export default React.forwardRef((props, ref) => <ModuleContainer frameRef={ref} {...props} />);