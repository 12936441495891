import React, { Component } from "react";

class Home extends Component {
  componentDidMount() {
    if (!this.props.user) {
      return;
    }

    let lastVisitedServicePath = localStorage.getItem("lastVisitedServicePath");

    let lastVisitedServicePathValid = false;
    if (this.props.user.roles.length > 0 && lastVisitedServicePath !== null) {
      for (let role of this.props.user.roles) {
        switch (role.project.code) {
          case "MM":
            if (lastVisitedServicePath === "/mm") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "AX_SUPPORT":
            localStorage.setItem("lastVisitedServicePath", "/support");
            this.props.history.push("/support");
            break;
          case "CP_PAY":
            localStorage.setItem("lastVisitedServicePath", "/cp_pay");
            this.props.history.push("/cp_pay");
            break;
          case "NPS":
            localStorage.setItem("lastVisitedServicePath", "/nps");
            this.props.history.push("/nps");
            break;
          case "FB":
            if (lastVisitedServicePath === "/carfin") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "PP":
            if (lastVisitedServicePath === "/pp") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "PF":
            if (lastVisitedServicePath === "/perekup") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "PPP":
            if (lastVisitedServicePath === "/ppp") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "CAL":
            if (lastVisitedServicePath === "/calendar") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "FL":
            if (lastVisitedServicePath === "/filter") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "AD":
            if (lastVisitedServicePath === "/adloader") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "PS":
            if (lastVisitedServicePath === "/parser") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "UA":
            if (lastVisitedServicePath === "/user_admin") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "NPS":
            if (lastVisitedServicePath === "/nps") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "CAR":
            if (lastVisitedServicePath === "/cars") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "CL":
            if (lastVisitedServicePath === "/clients") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "WP":
            if (lastVisitedServicePath === "/welcome") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "CS":
            if (lastVisitedServicePath === "/sales") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "KEY":
            if (lastVisitedServicePath === "/keys") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "VE":
            if (lastVisitedServicePath === "/veditor") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "XXX":
            if (lastVisitedServicePath === "/xxx") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "BI":
            if (lastVisitedServicePath === "/bi") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "MISC":
            if (lastVisitedServicePath === "/contracts") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "WIKI":
            if (lastVisitedServicePath === "/wiki") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "SEO":
            if (lastVisitedServicePath === "/seo") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "CS_NEWAUTO":
            if (lastVisitedServicePath === "/newcarsales") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "DEALER_ADMIN":
            if (lastVisitedServicePath === "/branch") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "CAR_GUARANTEE":
            if (lastVisitedServicePath === "/guarantee") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "QR":
            if (lastVisitedServicePath === "/qr") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "BX":
            if (lastVisitedServicePath === "/bipiumx") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "PPO":
            if (lastVisitedServicePath === "/ppo") {
              lastVisitedServicePathValid = true;
            }
            break;
          case "CP":
            if (role.code === "PRICER") {
              if (lastVisitedServicePath === "/pricer") {
                lastVisitedServicePathValid = true;
              }
            } else if (
              role.code === "HEAD_PRICER" ||
              role.code === "SUPER_HEAD_SALES" ||
              role.code === "HEAD_SALES" ||
              role.code === "HEAD_SERVICE"
            ) {
              if (lastVisitedServicePath === "/valuation") {
                lastVisitedServicePathValid = true;
              }
            } else if (role.code === "PURCHASER") {
              if (lastVisitedServicePath === "/buyer") {
                lastVisitedServicePathValid = true;
              }
            } else if (role.code === "OSS") {
              if (lastVisitedServicePath === "/oss") {
                lastVisitedServicePathValid = true;
              }
            } else if (role.code === "LOGIST") {
              if (lastVisitedServicePath === "/logist") {
                lastVisitedServicePathValid = true;
              }
            } else if (
              role.code === "CRIMINALIST" ||
              role.code === "SUPER_CRIMINALIST"
            ) {
              if (lastVisitedServicePath === "/forensic") {
                lastVisitedServicePathValid = true;
              }
            } else if (role.code === "MASTER_RECEIVER") {
              if (lastVisitedServicePath === "/inspector") {
                lastVisitedServicePathValid = true;
              }
            }
            break;
          default:
            break;
        }
      }

      if (lastVisitedServicePath === "/report") {
        lastVisitedServicePathValid = true;
      }
    }

    if (lastVisitedServicePathValid) {
      this.props.history.push(lastVisitedServicePath);
    } else {
      let role = this.props.user.roles[0];
      switch (role.project.code) {
        case "MM":
          localStorage.setItem("lastVisitedServicePath", "/mm");
          this.props.history.push("/mm");
          break;
        case "NPS":
          localStorage.setItem("lastVisitedServicePath", "/nps");
          this.props.history.push("/nps");
          break;
        case "FB":
          localStorage.setItem("lastVisitedServicePath", "/carfin");
          this.props.history.push("/carfin");
          break;
        case "PP":
          localStorage.setItem("lastVisitedServicePath", "/pp");
          this.props.history.push("/pp");
          break;
        case "PF":
          localStorage.setItem("lastVisitedServicePath", "/perekup");
          this.props.history.push("/perekup");
          break;
        case "PPP":
          localStorage.setItem("lastVisitedServicePath", "/ppp");
          this.props.history.push("/ppp");
          break;
        case "CAL":
          localStorage.setItem("lastVisitedServicePath", "/calendar");
          this.props.history.push("/calendar");
          break;
        case "FL":
          localStorage.setItem("lastVisitedServicePath", "/filter");
          this.props.history.push("/filter");
          break;
        case "AD":
          localStorage.setItem("lastVisitedServicePath", "/adloader");
          this.props.history.push("/adloader");
          break;
        case "PS":
          localStorage.setItem("lastVisitedServicePath", "/parser");
          this.props.history.push("/parser");
          break;
        case "UA":
          localStorage.setItem("lastVisitedServicePath", "/user_admin");
          this.props.history.push("/user_admin");
          break;
        case "CC":
          localStorage.setItem("lastVisitedServicePath", "/cc");
          this.props.history.push("/cc");
          break;
        case "CAR_PRICING":
          localStorage.setItem("lastVisitedServicePath", "/car-pricing");
          this.props.history.push("/car-pricing");
          break;
        case "DOCS":
          localStorage.setItem("lastVisitedServicePath", "/docs");
          this.props.history.push("/docs");
          break;
        case "CP":
          if (role.code === "PRICER") {
            localStorage.setItem("lastVisitedServicePath", "/pricer");
            this.props.history.push("/pricer");
          } else if (
            role.code === "HEAD_PRICER" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "HEAD_SALES" ||
            role.code === "PHOTOGRAPH"
          ) {
            localStorage.setItem("lastVisitedServicePath", "/valuation");
            this.props.history.push("/valuation");
          } else if (role.code === "PURCHASER") {
            localStorage.setItem("lastVisitedServicePath", "/buyer");
            this.props.history.push("/buyer");
          } else if (
            role.code === "CRIMINALIST" ||
            role.code === "SUPER_CRIMINALIST"
          ) {
            localStorage.setItem("lastVisitedServicePath", "/forensic");
            this.props.history.push("/forensic");
          } else if (role.code === "MASTER_RECEIVER") {
            localStorage.setItem("lastVisitedServicePath", "/inspector");
            this.props.history.push("/inspector");
          }
          break;
        default:
          break;
      }
    }
  }

  render() {
    return <div></div>;
  }
}

export default Home;
