import React, {Component} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {LinearProgress, ListItemIcon, ListSubheader} from "@material-ui/core";
import MailIcon from '@material-ui/icons/Mail';
import ListContext from "@material-ui/core/List/ListContext";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import axios from "axios";
import {AUTH_API_URL, MISC_API_URL} from "../lib/env";
import moment from "moment";

class Messages extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      myMessages: [],
      myMessagesCountFiltered: 0,
      selectedMessage: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.getMyMessages()
    localStorage.setItem("limit", 10)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState
  }

  openMessage(message) {
    this.setState({dialogOpen: true, selectedMessage: message});
    if (!message.is_read) {
      this.setRead(message.id)
    }
  }

  closeMessage() {
    if(!this.state.selectedMessage.is_read) {
      this.props.getUnreadMessagesCount();
    }

    this.getMyMessages()
    this.setState({dialogOpen: false, selectedMessage: null});
    localStorage.setItem("limit", this.state.limit)
  }

  getMyMessages() {
    let self = this;
    this.setState({loading: true})
    axios.post(MISC_API_URL + '/message/getMyMessages',
      {
        limit: localStorage.getItem("limit") && 10,
        offset: 0
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(function (response) {
        console.log(response.data);
        self.setState({
          myMessages: response.data.list,
          myMessagesCountFiltered: response.data.countFiltered,
          loading: false
        })
      }).catch(function (error) {
        self.setState({loading: true})
        console.log(error.message);
    });
  }

  setRead(messageId) {
    let self = this;
    axios.patch(MISC_API_URL + '/message',
      {
        id: messageId,
        is_read: true,
        readDate: new Date()
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(function (response) {
        console.log(response.data);
      }).catch(function (error) {
      console.log(error.message);
    });
  }

  showMoreMessages() {
    let limit = localStorage.getItem("limit")
    limit++
    localStorage.setItem("limit", limit)

    this.setState(
      {
        limit: limit
      }, () => {
        this.getMyMessages()
      }
    )
  }

  render() {
    if (this.state.loading) {
      return <LinearProgress />
    }

    return (
      <div style={{marginTop: '60px', padding: '20px'}}>
        <IconButton color="primary"
          style={{float: 'left', paddingRight: '10px', marginTop: '-8px'}}
          onClick={() => {localStorage.setItem("limit", 10); this.props.history.goBack()}}>
          <ChevronLeftIcon style={{ marginLeft: '0px' }} />
        </IconButton>
        <div style={{fontWeight: '600', fontSize: '20px'}}>Мои сообщения</div>

        <div className="paper">
        {this.state.myMessages.length === 0 &&
        <div>Нет новых сообщений</div>
        }
        <List>

          {
            this.state.myMessages.map(message =>
              <div>
                <ListItem
                onClick = {this.openMessage.bind(this, message)}
                button
                style={!message.is_read ? {backgroundColor: 'rgba(210,243,253,0.48)'} : {}}>
                {!message.is_read && <ListItemIcon>
                  <MailIcon color="primary"/>
                </ListItemIcon>}
                <ListItemText primary={message.title} secondary={
                  <div>
                    <div>{message.description.length > 100 ?
                      (message.description.substr(0, 100) + '...').split(/\r\n|\r|\n/).map(line => <div>{line}</div>) :
                      message.description.split(/\r\n|\r|\n/).map(line => <div>{line}</div>)}
                    </div>
                    <div style={{color: '#999', fontSize: '11px', paddingTop: '10px'}}>
                      {moment(message.createdDate).format("DD.MM.YYYY HH:mm")}
                    </div>
                  </div>
                }  />
              </ListItem>
                <Divider />
              </div>
            )
          }
        </List>

          {this.state.myMessagesCountFiltered > this.state.myMessages.length &&
          <Button
            onClick={this.showMoreMessages.bind(this)}
            className="button_regular_color button">
            Показать еще
          </Button>
          }


        </div>

        {this.state.selectedMessage &&
        <Dialog fullScreen open={this.state.dialogOpen} onClose={this.closeMessage.bind(this)} TransitionComponent={Transition}>
          <AppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.closeMessage.bind(this)} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                Сообщение
              </Typography>
            </Toolbar>
          </AppBar>
          <div style={{padding: '20px', paddingTop: '70px'}}>
            <div style={{fontSize: '18px', fontWeight: '900' }}>
              {this.state.selectedMessage.title}
            </div>
            <div style={{color: '#999', fontSize: '11px', paddingTop: '10px'}}>
              {moment(this.state.selectedMessage.createdDate).format("DD.MM.YYYY HH:mm")}
            </div>
            <div style={{paddingTop: '20px', fontSize: '16px'}}>
              {this.state.selectedMessage.description.split(/\r\n|\r|\n/).map(line => <div>{line.length === 0 ? '\u00A0' : line}</div>)}
            </div>
            {this.state.selectedMessage.url && <div>
              <img src={this.state.selectedMessage.url} style={{maxWidth: "800px"}} alt={""} />
            </div>}
          </div>
        </Dialog>}
      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Messages;