import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
} from "@material-ui/core";
import CarIcon from "@material-ui/icons/DriveEta";
import CarOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import DollarIcon from "@material-ui/icons/MonetizationOn";
import TagIcon from "@material-ui/icons/LocalOffer";
import FilterIcon from "@material-ui/icons/FilterList";
import ParserIcon from "@material-ui/icons/BugReport";
import UploadIcon from "@material-ui/icons/CloudUpload";
import PrintIcon from "@material-ui/icons/Print";
import UserIcon from "@material-ui/icons/AccountCircle";
import CarWashIcon from "@material-ui/icons/LocalCarWash";
import CalendarIcon from "@material-ui/icons/DateRange";
import { withRouter } from "react-router-dom";
import ReportIcon from "@material-ui/icons/ListAlt";
import LikeIcon from "@material-ui/icons/ThumbUpAlt";
import ClientIcon from "@material-ui/icons/Face";
import ExploreIcon from "@material-ui/icons/Explore";
import PinIcon from "@material-ui/icons/PersonPin";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import KeyIcon from "@material-ui/icons/VpnKey";
import VeditorIcon from "@material-ui/icons/BorderColor";
import DashboardIcon from "@material-ui/icons/BarChart";
import CCIcon from "@material-ui/icons/ContactPhone";
import DocsIcon from "@material-ui/icons/MenuBook";
import XXXIcon from "@material-ui/icons/Beenhere";
import BIIcon from "@material-ui/icons/TrendingUp";
import ContractsIcon from "@material-ui/icons/AllInbox";
import SeoIcon from "@material-ui/icons/EmojiPeople";
import SadminIcon from "@material-ui/icons/ImportantDevices";
import WikiIcon from "@material-ui/icons/ImportContacts";
import ExtensionIcon from "@material-ui/icons/Extension";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import PeopleIcon from "@material-ui/icons/People";
import HelpIcon from "@material-ui/icons/Help";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SecurityIcon from "@material-ui/icons/Security";
import QrCode2Icon from "@material-ui/icons/CropFree";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import BuildIcon from "@material-ui/icons/Build";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PaymentIcon from "@material-ui/icons/Payment";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import ShopIcon from "@material-ui/icons/Shop";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import { parseJwt } from "../lib/util";
import axios from "axios";
import { AUTH_API_URL, ML_API_URL } from "../lib/env";
import { CastForEducation } from "@material-ui/icons";

class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReportsOpen: false,
      BImenuAnchor: null,
      user: null,
      dashboards: [],
      missingPlan: false,
    };
  }

  compare(a, b) {
    if (a.project.name < b.project.name) {
      return -1;
    }
    if (a.project.name > b.project.name) {
      return 1;
    }
    return 0;
  }

  getDashboards() {
    let self = this;
    axios
      .get(AUTH_API_URL + "/users/getUserByToken", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        if (response.data.dashboardList) {
          self.setState({ dashboards: response.data.dashboardList });
        }
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("401") || error.message.includes("403")) {
          self.props.history.push("/signin");
        }
      });
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/signin");
    }
    let user = parseJwt(localStorage.getItem("token"));
    this.setState({ user });
    console.log(user);
    if (
      user.roleList.filter(
        (role) =>
          (role.code === "HEAD_SALES_CP" && role.project.code === "DASH") ||
          (role.code === "HEAD_SALES_CS" && role.project.code === "DASH")
      ).length > 0
    ) {
      this.getPlanMissing(user.sub);
    }

    if (user.dashboardCodes && user.dashboardCodes.length > 0) {
      this.getDashboards();
    }
  }

  getPlanMissing(username) {
    let self = this;
    axios
      .post(
        ML_API_URL + "/ml/missingPlan",
        {
          username: username,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(function (response) {
        self.setState({ missingPlan: !response.data });
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("401") || error.message.includes("403")) {
          self.props.history.push("/signin");
        }
      });
  }

  render() {
    let menus = [];
    let roles = this.props.user.roles;
    roles.sort(this.compare);

    for (let role of this.props.user.roles) {
      switch (role.project.code) {
        case "FB":
          menus.push(
            <ListItem
              button
              key={role.id}
              onClick={() => {
                this.props.history.push("/carfin");
                localStorage.setItem("lastVisitedServicePath", "/carfin");
                this.props.hideDrawer();
              }}
            >
              <ListItemIcon style={{ marginLeft: "7px" }}>
                <DollarIcon />
              </ListItemIcon>
              <ListItemText primary={role.project.name} />
            </ListItem>
          );
          break;
        case "CP":
          if (
            role.code === "HEAD_PRICER" ||
            role.code === "CM" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "CASHIER" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "HEAD_SALES" ||
            role.code === "HEAD_SERVICE" ||
            role.code === "GUARANTEE_MANAGER" ||
            role.code === "SUPER_HEAD_AND_BRANCH" ||
            role.code === "SECURITY"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/valuation");
                  localStorage.setItem("lastVisitedServicePath", "/valuation");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "PRICER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/pricer");
                  localStorage.setItem("lastVisitedServicePath", "/pricer");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "PURCHASER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/buyer");
                  localStorage.setItem("lastVisitedServicePath", "/buyer");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "LOGIST") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/logist");
                  localStorage.setItem("lastVisitedServicePath", "/logist");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "OSS" || role.code === "HEAD_OSS") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/oss");
                  localStorage.setItem("lastVisitedServicePath", "/oss");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (
            role.code === "CRIMINALIST" ||
            role.code === "SUPER_CRIMINALIST"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/forensic");
                  localStorage.setItem("lastVisitedServicePath", "/forensic");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "MASTER_RECEIVER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/inspector");
                  localStorage.setItem("lastVisitedServicePath", "/inspector");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PPP":
          if (
            role.code === "STOCK_CHECKER" ||
            role.code === "STOCKER" ||
            role.code === "MASTER_RECEIVER" ||
            role.code === "HEAD_PPP" ||
            role.code === "SENIOR_TECHNIC" ||
            role.code === "HEAD_SERVICE" ||
            role.code === "HEAD_SALES" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "SUPPLIER" ||
            role.code === "DESCRIPTOR" ||
            role.code === "PHOTOGRAPH"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/ppp");
                  localStorage.setItem("lastVisitedServicePath", "/ppp");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarWashIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CAL":
          if (
            role.code === "USER" ||
            role.code === "PURCHASER" ||
            role.code === "HEAD_SALES" ||
            role.code === "HEAD_SALES_CS" ||
            role.code === "TECHNIC" ||
            role.code === "MANAGER_SALES"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/calendar");
                  localStorage.setItem("lastVisitedServicePath", "/calendar");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "WP":
          if (role.code === "USER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/welcome");
                  localStorage.setItem("lastVisitedServicePath", "/welcome");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ExploreIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "MM":
          if (role.code === "MOBILE_ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/mm");
                  localStorage.setItem("lastVisitedServicePath", "/mm");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PinIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CS":
          if (
            role.code === "LOGIST" ||
            role.code === "FIN_MANAGER" ||
            role.code === "MANAGER" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "HEAD_SALES"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/sales");
                  localStorage.setItem("lastVisitedServicePath", "/sales");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "FL":
          if (role.code === "OPERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/filter");
                  localStorage.setItem("lastVisitedServicePath", "/filter");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <FilterIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PS":
          if (role.code === "OPERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/parser");
                  localStorage.setItem("lastVisitedServicePath", "/parser");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ParserIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "NPS":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/nps");
                  localStorage.setItem("lastVisitedServicePath", "/nps");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <LikeIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CAR":
          if (
            role.code === "STOCK_CHECKER" ||
            role.code === "PHOTOGRAPH" ||
            role.code === "CAR_EDITOR" ||
            role.code === "STOCKER" ||
            role.code === "ADMIN" ||
            role.code === "HEAD_SALES" ||
            role.code === "KOLESA_MAN" ||
            role.code === "LOGISTICS" ||
            role.code === "HEAD_SALES_CS" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "MANAGER_NEW_AUTO" ||
            role.code === "ROP_NEW_AUTO" ||
            role.code === "CQ" ||
            role.code === "ATZ" ||
            role.code === "VIDEOGRAPH" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "HEAD" ||
            role.code === "CM"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/cars");
                  localStorage.setItem("lastVisitedServicePath", "/cars");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CL":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/clients");
                  localStorage.setItem("lastVisitedServicePath", "/clients");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ClientIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "VE":
          if (role.code === "VALUE_EDITOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/veditor");
                  localStorage.setItem("lastVisitedServicePath", "/veditor");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <VeditorIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PP":
          if (role.code === "PRICE_PRINTER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/pp");
                  localStorage.setItem("lastVisitedServicePath", "/pp");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PF":
          menus.push(
            <ListItem
              button
              key={role.id}
              onClick={() => {
                this.props.history.push("/perekup");
                localStorage.setItem("lastVisitedServicePath", "/perekup");
                this.props.hideDrawer();
              }}
            >
              <ListItemIcon style={{ marginLeft: "7px" }}>
                <TagIcon />
              </ListItemIcon>
              <ListItemText primary={role.project.name} />
            </ListItem>
          );
          break;
        case "UA":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/user_admin");
                  localStorage.setItem("lastVisitedServicePath", "/user_admin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "KEY":
          if (
            role.code === "KEEPER" ||
            role.code === "USER" ||
            role.code === "PARKING" ||
            role.code === "CASHIER"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/keys");
                  localStorage.setItem("lastVisitedServicePath", "/keys");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <KeyIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CC":
          if (role.code === "SCRIPT_ADMIN" || role.code === "OPERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/cc");
                  localStorage.setItem("lastVisitedServicePath", "/cc");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CCIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "DOCS":
          if (role.code === "VIEWER" || role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/docs");
                  localStorage.setItem("lastVisitedServicePath", "/docs");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <DocsIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "XXX":
          if (
            role.code === "NEWS_EDITOR" ||
            role.code === "MODERATOR" ||
            role.code === "SUPPORTER" ||
            role.code === "CM" ||
            role.code === "HEAD_CM"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/xxx");
                  localStorage.setItem("lastVisitedServicePath", "/xxx");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <XXXIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        // case 'BI':
        //   if (role.code === 'CEO' || role.code === 'USER') {
        //     menus.push(
        //       <ListItem button key={role.id}
        //                 onClick={(e) => { this.setState({menuAnchor: e.currentTarget}) }}>
        //         <ListItemIcon style={{ marginLeft: '7px' }}><BIIcon /></ListItemIcon>
        //         <ListItemText primary={role.project.name} />
        //       </ListItem>
        //     );
        //   }
        //   break;
        case "MISC":
          if (role.code === "CONTRACTS_EDITOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/contracts");
                  localStorage.setItem("lastVisitedServicePath", "/contracts");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ContractsIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "SEO":
          if (role.code === "OPERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/seo");
                  localStorage.setItem("lastVisitedServicePath", "/seo");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <SeoIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "WIKI":
          if (
            role.code === "MANAGER_RENAULT" ||
            role.code === "MANAGER_LADA" ||
            role.code === "MANAGER_NISSAN" ||
            role.code === "MANAGER_CHEVY" ||
            role.code === "BUYER" ||
            role.code === "BUYER_TRAINEE" ||
            role.code === "BUYER_ROP" ||
            role.code === "BUYER_SUPER_ROP" ||
            role.code === "ADMIN" ||
            role.code === "CM" ||
            role.code === "PHOTOGRAPH" ||
            role.code === "SALES_ROP" ||
            role.code === "HEAD_PPP" ||
            role.code === "HEAD_SERVICE" ||
            role.code === "MP_BUYER" ||
            role.code === "MP_SERVICE" ||
            role.code === "MECHANIC" ||
            role.code === "TECHNIC" ||
            role.code === "LAWYER" ||
            role.code === "LOGIST" ||
            role.code === "SPP" ||
            role.code === "FIN_SERVICE" ||
            role.code === "OSS" ||
            role.code === "PHOENIX_ADMIN" ||
            role.code === "HEAD_NEW" ||
            role.code === "SALES_SUPER_ROP"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/wiki");
                  localStorage.setItem("lastVisitedServicePath", "/wiki");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <WikiIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "SADMIN":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/sadmin");
                  localStorage.setItem("lastVisitedServicePath", "/sadmin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <SadminIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "WWW_LADA_ADMIN":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/wwwladaadmin");
                  localStorage.setItem(
                    "lastVisitedServicePath",
                    "/wwwladaadmin"
                  );
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ExtensionIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "H_CS":
          if (role.code === "HEAD_SALES" || role.code === "MANAGER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/hypersales");
                  localStorage.setItem("lastVisitedServicePath", "/hypersales");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <LoyaltyIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "OR_UA":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/orionuseradmin");
                  localStorage.setItem(
                    "lastVisitedServicePath",
                    "/orionuseradmin"
                  );
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CS_NEWAUTO":
          if (
            role.code === "ATZ" ||
            role.code === "LOGIST" ||
            role.code === "FIN_MANAGER" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "HEAD_SALES" ||
            role.code === "HEAD_PHONE_SALE" ||
            role.code === "PHONE_SALE_MANAGER" ||
            role.code === "SPP" ||
            role.code === "MANAGER"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/newcarsales");
                  localStorage.setItem(
                    "lastVisitedServicePath",
                    "/newcarsales"
                  );
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <AirportShuttleIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "DEALER_ADMIN":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/branch");
                  localStorage.setItem("lastVisitedServicePath", "/branch");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ScheduleIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "EDU":
          if (
            role.code === "TEACHER" ||
            role.code === "RECRUITER" ||
            role.code === "HEAD_SALES"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/edu-journal");
                  localStorage.setItem(
                    "lastVisitedServicePath",
                    "/edu-journal"
                  );
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CastForEducation />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CAR_GUARANTEE":
          if (
            role.code === "MANAGER" ||
            role.code === "HEAD_SERVICE" ||
            role.code === "ADMIN"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/guarantee");
                  localStorage.setItem("lastVisitedServicePath", "/guarantee");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <SecurityIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "QR":
          if (
            role.code === "MANAGER" ||
            role.code === "HEAD" ||
            role.code === "SECURITY"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/qr");
                  localStorage.setItem("lastVisitedServicePath", "/qr");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <QrCode2Icon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CAR_PRICING":
          if (role.code === "INITIATOR" || role.code === "APPROVER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/car-pricing");
                  localStorage.setItem(
                    "lastVisitedServicePath",
                    "/car-pricing"
                  );
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "BX":
          if (
            role.code === "CC" ||
            role.code === "HEAD_SALES" ||
            role.code === "CQ" ||
            role.code === "ATZ" ||
            role.code === "ADMIN" ||
            role.code === "ORION" ||
            role.code === "CQ_SUPER" ||
            role.code === "MARKETING" ||
            role.code === "CQ_SUPER"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/bipiumx");
                  localStorage.setItem("lastVisitedServicePath", "/bipiumx");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PhoneCallbackIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PPO":
          if (
            role.code === "MP" ||
            role.code === "OPERATOR" ||
            role.code === "HEAD_PPO" ||
            role.code === "SERVICE_ADMIN" ||
            role.code === "MECHANIC"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/ppo");
                  localStorage.setItem("lastVisitedServicePath", "/ppo");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "LI":
          if (role.code === "LIMIT_EDITOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/limits");
                  localStorage.setItem("lastVisitedServicePath", "/limits");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <RemoveCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CP_PAY":
          if (
            role.code === "FD" ||
            role.code === "SPP" ||
            role.code === "FinDir" ||
            role.code === "ACCOUNTANT" ||
            role.code === "MANAGEMENT" ||
            role.code === "CASHIER"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/cp_pay");
                  localStorage.setItem("lastVisitedServicePath", "/cp_pay");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PaymentIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "IS":
          if (
            role.code === "MOP" ||
            role.code === "ROP" ||
            role.code === "ADMIN" ||
            role.code === "TECHNIC" ||
            role.code === "TV"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/is");
                  localStorage.setItem("lastVisitedServicePath", "/is");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <WatchLaterIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "ARABAX":
          if (
            role.code === "MOV" ||
            role.code === "HEAD_BUYER" ||
            role.code === "ADMIN"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/arabax");
                  localStorage.setItem("lastVisitedServicePath", "/arabax");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ShopIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        default:
          document.title = "AsterX";
          break;
      }
    }

    if (
      this.props.user.reportCodes !== null &&
      this.props.user.reportCodes.length > 0
    ) {
      menus.push(
        <ListItem
          button
          key={"report"}
          onClick={() => {
            this.props.history.push("/report");
            localStorage.setItem("lastVisitedServicePath", "/report");
            this.props.hideDrawer();
          }}
        >
          <ListItemIcon style={{ marginLeft: "7px" }}>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText primary="Отчеты" />
        </ListItem>
      );
    }

    menus.push(
      <ListItem
        button
        key={"Support"}
        onClick={() => {
          this.props.history.push("/support");
          localStorage.setItem("lastVisitedServicePath", "/support");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <FavoriteIcon />
        </ListItemIcon>
        <ListItemText primary={"Поддержка АХ"} />
      </ListItem>
    );

    menus.push(
      <ListItem
        button
        key={"Superset"}
        onClick={() => {
          window.open("http://analytics.asterx.uz/superset/welcome/", "_blank");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <AllInclusiveIcon />
        </ListItemIcon>
        <ListItemText primary={"Аналитика"} />
      </ListItem>
    );

    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
      <div>
        <Snackbar open={this.state.missingPlan}>
          <Alert severity="error">Не выставлены планы!</Alert>
        </Snackbar>

        <List>{menus}</List>

        <Menu
          id="bi-menu"
          anchorEl={this.state.menuAnchor}
          keepMounted
          open={Boolean(this.state.menuAnchor)}
          onClose={() => this.setState({ menuAnchor: null })}
        >
          {this.state.user &&
            this.state.dashboards &&
            this.state.dashboards.length > 0 &&
            this.state.dashboards.map((dashboard) => (
              <MenuItem
                key={dashboard.code}
                onClick={() => {
                  this.props.history.push({
                    pathname: "/bi",
                    state: { url: dashboard.url },
                  });
                  localStorage.setItem("lastVisitedServicePath", "/bi");
                  this.props.hideDrawer();
                  this.setState({ menuAnchor: null });
                }}
              >
                {dashboard.name}
              </MenuItem>
            ))}
        </Menu>
      </div>
    );
  }
}

export default withRouter(UserMenu);
