import React, { Component } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import {
  Button,
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Snackbar,
  Menu,
  MenuItem,
  Divider,
  CssBaseline,
  Badge,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import clsx from "clsx";
import logoSvg from "./img/logo.svg";
import UserMenu from "./components/user_menu";
import ModuleContainer from "./pages/module_container";
import {
  AUTH_API_URL,
  getUserRoleByToken,
  MISC_API_URL,
  subscribeToNotifications,
} from "./lib/env";
import { messaging } from "./init-fcm";
import Home from "./pages/home";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Profile from "./pages/profile";
import { parseJwt } from "./lib/util";
import moment from "moment";
import "./sass/main.sass";
import CalendarPage from "./pages/calendar";
import Utp from "./components/utp";
import Messages from "./pages/messages";

const drawerWidth = 300;
const mobileWidth = 500;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    zIndex: 200,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: window.outerWidth < mobileWidth ? 0 : theme.spacing(9) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      isDrawerOpen: false,
      user: null,
      documentTitle: "AsterX",
      isNotificationSnackbarOpen: false,
      anchorEl: null,
      isOnline: true,
      isNoInternetSnackbarOpen: false,
      isInternetSnackbarOpen: false,
      unreadMessagesCount: 0,
    };

    this.pricerFrameRef = React.createRef();
    this.valuationFrameRef = React.createRef();
    this.crminalistFrameRef = React.createRef();
    this.inspectorFrameRef = React.createRef();
    this.finbrokerFrameRef = React.createRef();
    this.pppFrameRef = React.createRef();
    this.ppFrameRef = React.createRef();
    this.mmFrameRef = React.createRef();
    this.filterFrameRef = React.createRef();
    this.authFrameRef = React.createRef();
    this.calFrameRef = React.createRef();
    this.reportFrameRef = React.createRef();
    this.buyerFrameRef = React.createRef();
    this.npsFrameRef = React.createRef();
    this.carFrameRef = React.createRef();
    this.clientFrameRef = React.createRef();
    this.valuationOssRef = React.createRef();
    this.welcomeFrameRef = React.createRef();
    this.salesFrameRef = React.createRef();
    this.keysFrameRef = React.createRef();
    this.veditorFrameRef = React.createRef();
    this.dashboardFrameRef = React.createRef();
    this.ccFrameRef = React.createRef();
    this.docsFrameRef = React.createRef();
    this.xxxFrameRef = React.createRef();
    this.xxxBIRef = React.createRef();
    this.valuationLogistRef = React.createRef();
    this.contractsFrameRef = React.createRef();
    this.wikiFrameRef = React.createRef();
    this.seoFrameRef = React.createRef();
    this.sadminFrameRef = React.createRef();
    this.wwwLadaAdminFrameRef = React.createRef();
    this.hyperSalesFrameRef = React.createRef();
    this.orionUserAdminFrameRef = React.createRef();
    this.newCarSalesFrameRef = React.createRef();
    this.branchFrameRef = React.createRef();
    this.eduJournalFrameRef = React.createRef();
    this.guaranteeJournalFrameRef = React.createRef();
    this.qrFrameRef = React.createRef();
    this.carPricingFrameRef = React.createRef();
    this.bipiumxFrameRef = React.createRef();
    this.bipiumxCqFrameRef = React.createRef();
    this.limitsFrameRef = React.createRef();
    this.isFrameRef = React.createRef();
    this.arabaxFrameRef = React.createRef();

    this.hideDrawer = this.hideDrawer.bind(this);
  }

  hideDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  handleIframeTask = (e) => {
    //console.log(e.origin);
    //console.log(e.data);
    // if (e.origin === 'http://localhost:3001') {
    //   alert(e.data)
    // }
  };

  subscribeToNotifications() {
    if (messaging === null) {
      return;
    }

    this.setState({ isNotificationSnackbarOpen: false });

    let self = this;
    getUserRoleByToken(this.state.token)
      .then((res) => {
        self.setState({ user: res.data });

        let isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (!isSafari) {
          Notification.requestPermission()
            .then((permission) => {
              console.log(permission);
              if (permission === "granted") {
                messaging.getToken().then((fcm_token) => {
                  console.log(fcm_token);
                  localStorage.setItem("fcm_token", fcm_token);
                  subscribeToNotifications(self.state.token, fcm_token)
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
              }
            })
            .catch(function (err) {
              console.log("ERROR: Unable to get permission to notify.");
              console.log(err);
            });
        }

        navigator.serviceWorker.addEventListener("message", (message) => {
          console.log("msg received:");
          console.log(message);
        });
      })
      .catch((err) => {
        self.props.history.push("/signin");
      });
  }

  getUser(token) {
    let self = this;
    axios
      .get(AUTH_API_URL + "/users/getUserByToken", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        self.setState({ user: response.data });
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("401") || error.message.includes("403")) {
          self.props.history.push("/signin");
        }
      });
  }

  handleMessageFromIframe = (e) => {
    if (e.data === "refresh me") {
      console.log(e);
      window.location.reload();
    }
  };

  componentDidMount() {
    let self = this;
    window.addEventListener("message", this.messageReceiver);

    // if no token in localStorage get the fuck out!
    if (localStorage.getItem("token") === null) {
      this.props.history.push("/signin");
      return;
    }

    // if token expired get the fuck out!
    if (
      moment(new Date(parseJwt(this.state.token).exp * 1000)).isBefore(moment())
    ) {
      this.props.history.push("/signin");
      return;
    }

    // Add event listener offline to detect network loss.
    window.addEventListener("offline", function (e) {
      self.setState({ isOnline: false, isNoInternetSnackbarOpen: true });
    });

    // Add event listener online to detect network recovery.
    window.addEventListener("online", function (e) {
      self.setState({ isOnline: true, isInternetSnackbarOpen: true });
    });

    this.getUser(localStorage.getItem("token"));

    this.getUnreadMessagesCount();

    if (messaging === null) {
      return;
    }

    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    messaging
      .getToken()
      .then((currentToken) => {
        console.log(currentToken);
        if (currentToken === null) {
          if (localStorage.getItem("blockNotifications") !== "1") {
            this.setState({ isNotificationSnackbarOpen: true });
          }
        }
        localStorage.setItem("fcm_token", currentToken);
        subscribeToNotifications(self.state.token, currentToken)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    messaging.onTokenRefresh(() => {
      messaging
        .getToken()
        .then((refreshedToken) => {
          console.log("Token refreshed.");
          subscribeToNotifications(self.state.token, refreshedToken)
            .then((res) => {
              console.log(res);
              localStorage.setItem("fcm_token", refreshedToken);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log("Unable to retrieve refreshed token ", err);
        });
    });
  }

  updateTitle(title) {
    let prefix = "";
    window.location.hostname.includes("test") ||
    window.location.hostname.includes("localhost")
      ? (prefix = "TEST ")
      : (prefix = "");
    this.setState({ documentTitle: prefix + title });
    document.title = prefix + title;
  }

  openDrawer() {
    this.setState({ isDrawerOpen: true });
  }

  openProfileMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  navProfile() {
    this.setState({ anchorEl: null });
    this.props.history.push("/profile");
  }

  signOut() {
    this.setState({ anchorEl: null });
    localStorage.removeItem("token");
    localStorage.removeItem("blockNotifications");
    this.props.history.push("/signin");

    // console.log(this.valuationFrameRef);
    //
    // if (this.valuationFrameRef.current !== null) {
    //   this.valuationFrameRef.contentWindow.postMessage('logout', '*');
    //   console.log('logout sent');
    // } else {
    //   this.props.history.push('/signin');
    // }
  }

  messageReceiver = (e) => {
    if (
      (e.origin.includes("localhost") || e.origin.includes("asterx.uz")) &&
      !e.data.payload &&
      !e.data.source
    ) {
      console.log(e.data);
      if (e.data.includes("logout")) {
        localStorage.removeItem("token");
        this.props.history.push("/signin");
      } else if (e.data.includes("token$")) {
        localStorage.setItem("token", e.data.split("$")[1]);
      }
    }
  };

  openNotifications() {
    this.props.history.push("/messages");
  }

  getUnreadMessagesCount() {
    let self = this;
    axios
      .get(MISC_API_URL + "/message/getUnreadMessagesCount", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        self.setState({
          unreadMessagesCount: response.data,
        });
      })
      .catch(function (error) {
        console.log(error.message);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Utp />
        <CssBaseline />
        <AppBar
          position="fixed"
          style={
            this.state.isOnline
              ? { backgroundColor: "#333" }
              : { backgroundColor: "#ff0540" }
          }
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.isDrawerOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.setState({ isDrawerOpen: true })}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.state.isDrawerOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: "1" }}>
              {this.state.documentTitle}
            </Typography>

            <div>
              <IconButton
                aria-label="notifications"
                aria-controls="menu-appbar"
                onClick={this.openNotifications.bind(this)}
                color="inherit"
              >
                {false ? (
                  <NotificationsIcon />
                ) : (
                  <Badge
                    badgeContent={this.state.unreadMessagesCount}
                    color="secondary"
                  >
                    <NotificationsActiveIcon color="#FF4545" />
                  </Badge>
                )}
              </IconButton>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.openProfileMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(this.state.anchorEl)}
                onClose={() => this.setState({ anchorEl: null })}
              >
                <MenuItem onClick={this.navProfile.bind(this)}>
                  Профиль
                </MenuItem>
                <MenuItem onClick={this.signOut.bind(this)}>Выход</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.isDrawerOpen,
            [classes.drawerClose]: !this.state.isDrawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.state.isDrawerOpen,
              [classes.drawerClose]: !this.state.isDrawerOpen,
            }),
          }}
          open={this.state.isDrawerOpen}
        >
          <div className={classes.toolbar}>
            <object
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
              type="image/svg+xml"
              data={logoSvg}
              aria-label="logo"
              height="35"
              border="0"
            />
            <IconButton onClick={() => this.setState({ isDrawerOpen: false })}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {this.state.user && (
            <UserMenu
              user={this.state.user}
              hideDrawer={this.hideDrawer}
              openDrawer={this.openDrawer.bind(this)}
              isDrawerOpen={this.state.isDrawerOpen}
            />
          )}
        </Drawer>

        <main className={classes.content}>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => <Home {...props} user={this.state.user} />}
            />
            <Route
              exact
              path="/messages"
              component={(props) => (
                <Messages
                  {...props}
                  user={this.state.user}
                  getUnreadMessagesCount={this.getUnreadMessagesCount.bind(
                    this
                  )}
                />
              )}
            />

            <Route exact path="/carfin">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://carfin.asterx.uz"
                    : "https://test-uz-carfin.asterx.kz"
                }
                ref={this.finbrokerFrameRef}
                id="carfin"
                token={this.state.token}
                updateTitle={() => this.updateTitle("CarFin")}
              />
            </Route>
            <Route exact path="/welcome">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://welcome.asterx.uz"
                    : "https://test-uz-welcome.asterx.kz"
                }
                ref={this.welcomeFrameRef}
                id="welcome"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Цифровой путеводитель")}
              />
            </Route>
            <Route exact path="/valuation">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://valuation.asterx.uz/deals_list"
                    : "https://test-uz-valuation.asterx.kz/deals_list"
                }
                //moduleURL = {'https://valuation.asterx.uz/deals_list'}
                ref={(e) => {
                  this.valuationFrameRef = e;
                }}
                id="valuation"
                token={this.state.token}
                updateTitle={() => this.updateTitle("АвтоВыкуп")}
              />
            </Route>
            <Route exact path="/oss">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://oss.asterx.uz"
                    : "https://test-uz-oss.asterx.kz"
                }
                ref={this.valuationOssRef}
                id="oss"
                token={this.state.token}
                updateTitle={() => this.updateTitle("АвтоВыкуп")}
              />
            </Route>
            <Route exact path="/logist">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://logist.asterx.uz"
                    : "https://test-uz-logist.asterx.kz"
                }
                ref={this.valuationLogistRef}
                id="logist"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Логист")}
              />
            </Route>
            <Route exact path="/buyer">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://buyer.asterx.uz"
                    : "https://test-uz-buyer.asterx.kz"
                }
                ref={this.buyerFrameRef}
                id="buyer"
                token={this.state.token}
                updateTitle={() => this.updateTitle("АвтоВыкуп")}
              />
            </Route>
            <Route exact path="/pricer">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://valuation.asterx.uz/get_next_deal"
                    : "https://test-uz-valuation.asterx.kz/get_next_deal"
                }
                //moduleURL = {'https://valuation.asterx.uz/get_next_deal'}
                ref={this.pricerFrameRef}
                id="pricer"
                token={this.state.token}
                updateTitle={() => this.updateTitle("АвтоВыкуп")}
              />
            </Route>
            <Route exact path="/inspector">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://inspector.asterx.uz"
                    : "https://test-uz-inspector.asterx.kz"
                }
                ref={this.inspectorFrameRef}
                id="inspector"
                token={this.state.token}
                updateTitle={() => this.updateTitle("АвтоВыкуп")}
              />
            </Route>
            <Route exact path="/forensic">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://forensic.asterx.uz"
                    : "https://test-uz-forensic.asterx.kz"
                }
                ref={this.crminalistFrameRef}
                id="forensic"
                token={this.state.token}
                updateTitle={() => this.updateTitle("АвтоВыкуп")}
              />
            </Route>
            <Route exact path="/ppp">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://ppp.asterx.uz"
                    : "https://test-uz-ppp.asterx.kz"
                }
                ref={this.pppFrameRef}
                id="ppp"
                token={this.state.token}
                updateTitle={() => this.updateTitle("ППП")}
              />
            </Route>
            <Route exact path="/filter">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://filter.asterx.uz"
                    : "https://test-uz-filter.asterx.kz"
                }
                ref={this.filterFrameRef}
                id="filter"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Фильтр")}
              />
            </Route>
            <Route exact path="/parser">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://parser.asterx.uz"
                    : "https://test-uz-parser.asterx.kz"
                }
                ref={this.parserFrameRef}
                id="parser"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Парсер")}
              />
            </Route>
            <Route exact path="/adloader">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://adloader.asterx.uz"
                    : "https://test-uz-adloader.asterx.kz"
                }
                ref={this.adloaderFrameRef}
                id="adloader"
                token={this.state.token}
                updateTitle={() => this.updateTitle("AdLoader")}
              />
            </Route>
            <Route exact path="/pp">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://pp.asterx.uz"
                    : "https://test-uz-pp.asterx.kz"
                }
                ref={this.ppFrameRef}
                id="pp"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Печать ценников")}
              />
            </Route>
            <Route exact path="/user_admin">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://auth.asterx.uz"
                    : "https://test-uz-auth.asterx.kz"
                }
                ref={this.authFrameRef}
                id="user_admin"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Админка")}
              />
            </Route>
            <Route exact path="/calendar">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://calendar.asterx.uz/calendar"
                    : "https://test-uz-calendar.asterx.kz/calendar"
                }
                ref={this.calFrameRef}
                id="calendar"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Календарь")}
              />
            </Route>
            <Route exact path="/report">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://report.asterx.uz"
                    : "https://test-uz-report.asterx.kz"
                }
                ref={this.reportFrameRef}
                id="report"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Отчеты")}
              />
            </Route>
            <Route exact path="/nps">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://nps-admin.asterx.uz"
                    : "https://test-uz-nps-admin.asterx.kz"
                }
                frameRef={this.npsFrameRef}
                id="nps"
                token={this.state.token}
                updateTitle={() => this.updateTitle("MyClient")}
              />
            </Route>
            <Route exact path="/cars">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://car.asterx.uz"
                    : "https://test-uz-car.asterx.kz"
                }
                frameRef={this.carFrameRef}
                id="cars"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Авто")}
              />
            </Route>
            <Route exact path="/clients">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://client.asterx.uz"
                    : "https://test-uz-client.asterx.kz"
                }
                frameRef={this.clientFrameRef}
                id="clients"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Клиенты")}
              />
            </Route>
            <Route exact path="/mm">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://mm.asterx.uz"
                    : "https://test-uz-mm.asterx.kz"
                }
                frameRef={this.mmFrameRef}
                id="mm"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Мобильный менеджер")}
              />
            </Route>
            <Route exact path="/sales">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://sales.asterx.uz"
                    : "https://test-uz-sales.asterx.kz"
                }
                frameRef={this.salesFrameRef}
                id="sales"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Авто Продажа")}
              />
            </Route>
            <Route exact path="/keys">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://keys.asterx.uz"
                    : "https://test-uz-keys.asterx.kz"
                }
                ref={this.keysFrameRef}
                id="keys"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Ключница")}
              />
            </Route>
            <Route exact path="/veditor">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://veditor.asterx.uz"
                    : "https://test-uz-veditor.asterx.kz"
                }
                ref={this.veditorFrameRef}
                id="veditor"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Техсостояние авто")}
              />
            </Route>
            <Route exact path="/cc">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://cca.asterx.uz"
                    : "https://test-uz-cca.asterx.kz"
                }
                ref={this.ccFrameRef}
                id="cc"
                token={this.state.token}
                updateTitle={() => this.updateTitle("КЦ")}
              />
            </Route>
            <Route exact path="/docs">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://docs.asterx.uz"
                    : "https://test-uz-docs.asterx.kz"
                }
                ref={this.docsFrameRef}
                id="docs"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Инструкции")}
              />
            </Route>
            <Route exact path="/xxx">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://xxx.asterx.uz"
                    : "https://test-uz-xxx.asterx.kz"
                }
                ref={this.xxxFrameRef}
                id="xxx"
                token={this.state.token}
                updateTitle={() => this.updateTitle("XXX")}
              />
            </Route>
            <Route exact path="/contracts">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://contracts.asterx.uz"
                    : "https://test-uz-contracts.asterx.kz"
                }
                ref={this.contractsFrameRef}
                id="contracts"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Контракты")}
              />
            </Route>
            <Route exact path="/wiki">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://wiki.asterx.uz"
                    : "https://test-uz-wiki.asterx.kz"
                }
                ref={this.wikiFrameRef}
                id="wiki"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Wiki")}
              />
            </Route>
            <Route exact path="/seo">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://seo.asterx.uz"
                    : "https://test-uz-seo.asterx.kz"
                }
                ref={this.seoFrameRef}
                id="seo"
                token={this.state.token}
                updateTitle={() => this.updateTitle("СЭО")}
              />
            </Route>
            <Route exact path="/sadmin">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://sadmin.asterx.uz"
                    : "https://test-uz-sadmin.asterx.kz"
                }
                ref={this.sadminFrameRef}
                id="sadmin"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Супер Админ")}
              />
            </Route>
            <Route exact path="/orionuseradmin">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://orion-user-admin-ui.asterx.uz"
                    : "https://test-uz-orion-user-admin-ui.asterx.kz"
                }
                ref={this.orionUserAdminFrameRef}
                id="orionuseradmin"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Orion Админ")}
              />
            </Route>
            <Route exact path="/newcarsales">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://newcar-sales.asterx.uz/home/page/0"
                    : "https://test-uz-newcar-sales.asterx.kz/home/page/0"
                }
                ref={this.newCarSalesFrameRef}
                id="newcarsales"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Продажи New")}
              />
            </Route>
            <Route exact path="/branch">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://branch.asterx.uz"
                    : "https://test-uz-branch.asterx.kz"
                }
                ref={this.branchFrameRef}
                id="branch"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Салоны")}
              />
            </Route>
            <Route exact path="/edu-journal">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://edu-journal.asterx.uz"
                    : "https://test-uz-edu-journal.asterx.kz"
                }
                ref={this.eduJournalFrameRef}
                id="edu-journal"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Журнал обучения")}
              />
            </Route>
            <Route exact path="/guarantee">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://guarantee.asterx.uz"
                    : "https://test-uz-guarantee.asterx.kz"
                }
                ref={this.guaranteeJournalFrameRef}
                id="guarantee"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Гарантии")}
              />
            </Route>
            <Route exact path="/qr">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://qr.asterx.uz"
                    : "https://test-uz-qr.asterx.kz"
                }
                ref={this.qrFrameRef}
                id="qr"
                token={this.state.token}
                updateTitle={() => this.updateTitle("QR")}
              />
            </Route>
            <Route exact path="/car-pricing">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://car-pricing.asterx.uz"
                    : "https://test-uz-car-pricing.asterx.kz"
                }
                ref={this.carPricingFrameRef}
                id="car-pricing"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Ценообразование")}
              />
            </Route>
            <Route exact path="/bipiumx">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://bipiumx.asterx.uz"
                    : "https://test-uz-bipiumx.asterx.kz"
                }
                ref={this.bipiumxFrameRef}
                id="bipiumx"
                token={this.state.token}
                updateTitle={() => this.updateTitle("BipiumX")}
              />
            </Route>
            <Route exact path="/ppo">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://ppo.asterx.uz"
                    : "https://test-uz-ppo.asterx.kz"
                }
                ref={this.ppoFrameRef}
                id="ppo"
                token={this.state.token}
                updateTitle={() => this.updateTitle("ППО")}
              />
            </Route>
            <Route exact path="/limits">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://limits.asterx.uz"
                    : "https://test-uz-limits.asterx.kz"
                }
                ref={this.limitsFrameRef}
                id="limits"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Лимиты")}
              />
            </Route>
            <Route exact path="/support">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://support-ax.asterx.uz"
                    : "https://test-uz-support-ax.asterx.kz"
                }
                ref={this.supportFrameRef}
                id="support"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Поддержка АХ")}
              />
            </Route>
            <Route exact path="/cp_pay">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://cp-payment.asterx.uz"
                    : "https://test-uz-cp-payment.asterx.kz"
                }
                ref={this.cpPayFrameRef}
                id="cp_pay"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Оплата")}
              />
            </Route>
            <Route exact path="/is">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://is.asterx.uz"
                    : "https://test-uz-is.asterx.kz"
                }
                ref={this.isFrameRef}
                id="is"
                token={this.state.token}
                updateTitle={() => this.updateTitle("График выдач")}
              />
            </Route>
            <Route exact path="/arabax">
              <ModuleContainer
                moduleURL={
                  window.location.hostname === "asterx.uz"
                    ? "https://arabax.asterx.uz"
                    : "https://test-uz-arabax.asterx.kz"
                }
                ref={this.arabaxFrameRef}
                id="arabax"
                token={this.state.token}
                updateTitle={() => this.updateTitle("ArabaX")}
              />
            </Route>

            <Route
              exact
              path="/bi"
              render={(props) => (
                <ModuleContainer
                  {...props}
                  moduleURL={
                    props.location.state ? props.location.state.url : null
                  }
                  ref={this.xxxBIRef}
                  id="bi"
                  token={this.state.token}
                  updateTitle={() => this.updateTitle("BI")}
                />
              )}
            />
            <Route
              exact
              path="/bipiumx/cq/:id"
              render={(props) => (
                <ModuleContainer
                  {...props}
                  moduleURL={
                    props.location.state ? props.location.state.url : null
                  }
                  ref={this.bipiumxCqFrameRef}
                  id="bipiumxCq"
                  token={this.state.token}
                  updateTitle={() => this.updateTitle("BipiumX")}
                />
              )}
            />

            <Route exact path="/profile" component={Profile} />
            <Route exact path="/profile/calendar" component={CalendarPage} />
          </Switch>
        </main>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.isNotificationSnackbarOpen}
          autoHideDuration={60000}
          onClose={() => this.setState({ isNotificationSnackbarOpen: false })}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">Хотите получать уведомления?</span>}
          action={[
            <Button
              key="ok"
              color="primary"
              size="small"
              onClick={this.subscribeToNotifications.bind(this)}
            >
              Да
            </Button>,
            <Button
              key="no"
              color="secondary"
              size="small"
              onClick={() => {
                localStorage.setItem("blockNotifications", "1");
                this.setState({ isNotificationSnackbarOpen: false });
              }}
            >
              Нет
            </Button>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={() =>
                this.setState({ isNotificationSnackbarOpen: false })
              }
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isNoInternetSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => this.setState({ isNoInternetSnackbarOpen: false })}
          ContentProps={{
            "aria-describedby": "message-id",
            style: { backgroundColor: "red" },
          }}
          message={<span id="message-id">Связь с Интернет прервана :(</span>}
          action={[]}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isInternetSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => this.setState({ isInternetSnackbarOpen: false })}
          ContentProps={{
            "aria-describedby": "message-id",
            style: { backgroundColor: "green" },
          }}
          message={
            <span id="message-id">Связь с Интернет восстановлена! :)</span>
          }
          action={[]}
        />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
