export const UTP_QS = [
  {
    question: 'Какой максимальный срок кредитования на автомобиль?',
    utpNo: 20,
    answers: [
      {
        name: '3 года',
        correct: false
      },
      {
        name: '5 лет',
        correct: false
      },
      {
        name: '7 лет',
        correct: true
      },
      {
        name: '10 лет',
        correct: false
      },
      {
        name: '1 год',
        correct: false
      },
    ]
  },
  {
    question: 'В течение какого период возможно обменять приобретенный автомобиль?',
    utpNo: 21,
    answers: [
      {
        name: 'до 3 дней',
        correct: false
      },
      {
        name: 'до 7 дней',
        correct: true
      },
      {
        name: 'до 10 дней',
        correct: false
      },
      {
        name: 'до 14 дней',
        correct: false
      },
      {
        name: 'согласно закону о потребителях',
        correct: false
      },
    ]
  },
  {
    question: 'Какой минимальный первоначальный взнос по кредиту?',
    utpNo: 22,
    answers: [
      {
        name: '0%',
        correct: false
      },
      {
        name: '10%',
        correct: true
      },
      {
        name: '20%',
        correct: false
      },
      {
        name: '50%',
        correct: false
      },
      {
        name: '70%',
        correct: false
      },
    ]
  },
  {
    question: 'Какой минимальный год выпуска автомобиля, на который предоставляется продленная техническая гарантия?',
    utpNo: 23,
    answers: [
      {
        name: '2011',
        correct: false
      },
      {
        name: '2012',
        correct: true
      },
      {
        name: '2014',
        correct: false
      },
      {
        name: '2017',
        correct: false
      },
      {
        name: 'без ограничения',
        correct: false
      },
    ]
  },
  {
    question: 'На какой процент жалоб и предложений предоставляется обратная связь клиентам?',
    utpNo: 24,
    answers: [
      {
        name: '100%',
        correct: true
      },
      {
        name: '50%',
        correct: false
      },
      {
        name: 'не предоставляется',
        correct: false
      },
      {
        name: '90%',
        correct: false
      },
      {
        name: '75%',
        correct: false
      },
    ]
  },
  {
    question: 'Какой максимальный пробег автомобиля, на который распространяется продленная техническая гарантия?',
    utpNo: 25,
    answers: [
      {
        name: 'без ограничения',
        correct: false
      },
      {
        name: 'до 100 000',
        correct: false
      },
      {
        name: 'до 150 000',
        correct: true
      },
      {
        name: 'до 50 000',
        correct: false
      },
      {
        name: 'до 75 000',
        correct: false
      },
    ]
  },
  {
    question: 'На что предоставляется продленная техническая гарантия?',
    utpNo: 26,
    answers: [
      {
        name: 'На ходовую часть',
        correct: false
      },
      {
        name: 'На все',
        correct: false
      },
      {
        name: 'Только на двигатель',
        correct: false
      },
      {
        name: 'Только на основные узлы и агрегаты',
        correct: true
      },
      {
        name: 'На электронику',
        correct: false
      },
    ]
  },
  {
    question: 'Какая стоимость транзитных номеров при продаже автомобиля?',
    utpNo: 27,
    answers: [
      {
        name: '5000 тенге',
        correct: false
      },
      {
        name: 'бесплатно',
        correct: true
      },
      {
        name: 'в зависимости от стоимости автомобиля',
        correct: false
      },
      {
        name: '1000 тенге',
        correct: false
      },
      {
        name: 'как договоритесь',
        correct: false
      },
    ]
  },
  {
    question: 'На какой срок действует продленная техническая гарантия?',
    utpNo: 28,
    answers: [
      {
        name: '1 месяц',
        correct: false
      },
      {
        name: 'полгода',
        correct: false
      },
      {
        name: '1 год',
        correct: true
      },
      {
        name: '3 года',
        correct: false
      },
      {
        name: '3 месяца',
        correct: false
      },
    ]
  },
  {
    question: 'Выберите правильный вариант УТП',
    utpNo: 29,
    answers: [
      {
        name: 'Бесплатная техническая гарантия 30 дней',
        correct: true
      },
      {
        name: 'Платная техническая гарантия 30 дней',
        correct: false
      },
      {
        name: 'Бесплатная техническая гарантия 7 дней',
        correct: false
      },
      {
        name: 'Платная техническая гарантия 7 дней',
        correct: false
      },
      {
        name: 'Бесплатная сервисная гарантия 7 дней',
        correct: false
      },
    ]
  },
  {
    question: 'Выберите правильный вариант УТП',
    utpNo: 30,
    answers: [
      {
        name: '6 месяцев продленная техническая гарантия',
        correct: false
      },
      {
        name: '12 месяцев продленная техническая гарантия',
        correct: true
      },
      {
        name: '24 месяца продленная техническая гарантия',
        correct: false
      },
      {
        name: '6 месяцев техническая гарантия',
        correct: false
      },
      {
        name: '12 месяцев техническая гарантия',
        correct: false
      },
    ]
  },
  {
    question: 'Выберите правильный вариант УТП',
    utpNo: 31,
    answers: [
      {
        name: '3 дня на обмен приобретенного автомобиля',
        correct: false
      },
      {
        name: '7 дней на обмен приобретенного автомобиля',
        correct: true
      },
      {
        name: '14 дней на обмен приобретенного автомобиля',
        correct: false
      },
      {
        name: '7 дней на возврат приобретенного автомобиля',
        correct: false
      },
      {
        name: '14 дней на возврат приобретенного автомобиля',
        correct: false
      },
    ]
  },
  {
    question: 'Выберите правильный вариант УТП',
    utpNo: 32,
    answers: [
      {
        name: 'Более 5000 автомобилей в наличии',
        correct: false
      },
      {
        name: 'Менее 1000 автомобилей в наличии',
        correct: false
      },
      {
        name: 'Более 3500 автомобилей в наличии',
        correct: true
      },
      {
        name: 'Менее 1500 автомобилей в наличии',
        correct: false
      },
      {
        name: 'Менее 2000 автомобилей в наличии',
        correct: false
      },
    ]
  },
  {
    question: 'Выберите правильный вариант УТП',
    utpNo: 33,
    answers: [
      {
        name: 'Специальные варианты кредитования',
        correct: false
      },
      {
        name: 'Специальные условия кредитования',
        correct: true
      },
      {
        name: 'Особые условия кредитования',
        correct: false
      },
      {
        name: 'Особые варианты кредитования',
        correct: false
      },
      {
        name: 'Банковские условия кредитования',
        correct: false
      },
    ]
  },
  {
    question: 'Выберите правильный вариант УТП',
    utpNo: 34,
    answers: [
      {
        name: 'Гарантия финансовой чистоты',
        correct: false
      },
      {
        name: 'Гарантия чистоты автомобиля',
        correct: false
      },
      {
        name: 'Гарантия особой чистоты',
        correct: false
      },
      {
        name: 'Гарантия юридической чистоты',
        correct: true
      },
      {
        name: 'Гарантия чистоты стока',
        correct: false
      },
    ]
  },
  {
    question: 'Выберите правильный вариант УТП',
    utpNo: 35,
    answers: [
      {
        name: 'Служба Поддержки Бизнеса',
        correct: false
      },
      {
        name: 'Служба Поддержки Физ.лиц',
        correct: false
      },
      {
        name: 'Служба Поддержки Клиентов',
        correct: true
      },
      {
        name: 'Служба Поддержания Работников',
        correct: false
      },
      {
        name: 'Служба Поддержания Клиентов',
        correct: false
      },
    ]
  },
  {
    question: 'На что предоставляется бесплатная гарантия 30 дней?',
    utpNo: 36,
    answers: [
      {
        name: 'На двигатель',
        correct: false
      },
      {
        name: 'На Двигатель и коробку передач',
        correct: true
      },
      {
        name: 'на основные узлы и агрегаты',
        correct: false
      },
      {
        name: 'на рулевую, тормозную и топливную системы',
        correct: false
      },
      {
        name: 'на все',
        correct: false
      },
    ]
  },
  {
    question: 'Превышение какого пробега выходит за рамки бесплатной 30ти дневной гарантии',
    utpNo: 37,
    answers: [
      {
        name: '500 км',
        correct: false
      },
      {
        name: '1000 км',
        correct: false
      },
      {
        name: '1500 км',
        correct: true
      },
      {
        name: '2000 км',
        correct: false
      },
      {
        name: '2500 км',
        correct: false
      },
    ]
  },
  {
    question: 'Превышение какого пробега выходит за рамки продленной годовой гарантии',
    utpNo: 38,
    answers: [
      {
        name: '5 000 км',
        correct: false
      },
      {
        name: '10 000 км',
        correct: false
      },
      {
        name: '15 000 км',
        correct: true
      },
      {
        name: '20 000 км',
        correct: false
      },
      {
        name: '25 000 км',
        correct: false
      },
    ]
  },
  {
    question: 'Сколько сервисных  пунктов  в листе проверки авто?',
    utpNo: 38,
    answers: [
      {
        name: '124',
        correct: false
      },
      {
        name: '244',
        correct: false
      },
      {
        name: '424',
        correct: false
      },
      {
        name: '69',
        correct: false
      },
      {
        name: '224',
        correct: true
      },
    ]
  },
  {
    question: 'Что такое Aster Check?',
    utpNo: 39,
    answers: [
      {
        name: 'Название нового сайта Aster ',
        correct: false
      },
      {
        name: 'Название нового дилера Aster ',
        correct: false
      },
      {
        name: 'Сервис для согласования сделок',
        correct: false
      },
      {
        name: 'Товарный чек, который выдается при покупке авто в Aster ',
        correct: false
      },
      {
        name: 'Сервис по проверке истории владения авто от Aster ',
        correct: true
      },
    ]
  },
  {
    question: 'Какие выгоды получает клиент по программе Trade-in при покупке авто с пробегом в Aster?',
    utpNo: 40,
    answers: [
      {
        name: 'Скидка 5%',
        correct: false
      },
      {
        name: 'Скидка 2%',
        correct: true
      },
      {
        name: 'Как договорится',
        correct: false
      },
      {
        name: 'Никаких выгод',
        correct: false
      },
      {
        name: '10% к Стоимости выкупаемого авто ',
        correct: false
      },
    ]
  },
  {
    question: 'Какие автомобили относятся к категории Карбазар?',
    utpNo: 45,
    answers: [
      {
        name: 'Имеющие серьезные дефекты салона',
        correct: false
      },
      {
        name: 'Имеющие видимые на фото серьезные кузовные дефекты ',
        correct: true
      },
      {
        name: 'Имеющие серьезные неисправности двигателя или КПП',
        correct: false
      },
      {
        name: 'Авто, со следами сварочных работ после ДТП',
        correct: false
      },
      {
        name: 'Авто  1995 г.в. и старше',
        correct: false
      },
    ]
  },
  {
    question: 'К какой категории относятся внешне целые автомобили, на которые нет бесплатной 30ти дневной гарантии? ',
    utpNo: 46,
    answers: [
      {
        name: 'Карбазар',
        correct: false
      },
      {
        name: 'Серый Aster',
        correct: false
      },
      {
        name: 'Aster без гарантии',
        correct: true
      },
      {
        name: 'такой категории не существует',
        correct: false
      },
      {
        name: 'Не знаю',
        correct: false
      },
    ]
  },
  {
    question: 'Какой штраф применяется на МОП, при просрочке Запланированного действия?',
    utpNo: 47,
    answers: [
      {
        name: '1 000 тг',
        correct: false
      },
      {
        name: '500 тг',
        correct: true
      },
      {
        name: '100 тг',
        correct: false
      },
      {
        name: '10 000 тг',
        correct: false
      },
      {
        name: 'Штрафа нет',
        correct: false
      },
    ]
  },
  {
    question: 'Какой штраф применяется на МОП при консультирование клиента на стоке без открытого РЛ? (демонстрация а/м клиенту на стоке без предварительного подбора а/м в РЛ - ЗАПРЕЩЕНО.)',
    utpNo: 48,
    answers: [
      {
        name: '5 000 тг',
        correct: false
      },
      {
        name: '50 000 тг',
        correct: true
      },
      {
        name: '2 500 тг',
        correct: false
      },
      {
        name: '500 тг',
        correct: false
      },
      {
        name: 'Штрафа нет',
        correct: false
      },
    ]
  },
]

// id, utpNo, username, date, correct