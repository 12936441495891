import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import { AUTH_API_URL } from "../lib/env";
import {parseJwt} from "../lib/util";


class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem('token'),
      username: '',
      password: '',
      isInfoDialogOpen: false,
      authErrorText: ''
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSignIn(e) {
    e.preventDefault();

    let self = this;
    axios.post(AUTH_API_URL + '/authenticate', {
      username: this.state.username,
      password: this.state.password
    }).then(function (response) {
      localStorage.setItem('token', response.data.token);

      let user = parseJwt(response.data.token)

      self.props.history.push('/')
    }).catch(function (error) {
      console.log(error.message);
      if (error.message.includes('401')) {
        alert('Неверный логин или пароль. Проверьте, нет ли опечаток.');
      }
    });
  }

  handleCloseInfoDialog() {
    this.setState({
      isInfoDialogOpen: false,
    })
  }

  render() {
    return (
      <div
        style={{ textAlign: "center" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '85vh' }}>
          <Grid item xs={12}>
            <form
              onSubmit={this.handleSignIn.bind(this)}>
              <Paper
                style={{
                  padding: '60px',
                  paddingBottom: '60px',
                  minWidth: '150px',
                  maxWidth: '250px'
                }}>
                <Grid container
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      component="h3">
                      Авторизация
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      id="password"
                      label={"Имя пользователя"}
                      type="text"
                      value={this.state.username}
                      onChange={this.handleChange('username')}
                      margin="normal"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                    <TextField
                      style={{ width: '100%' }}
                      id="password"
                      label={"Пароль"}
                      type="password"
                      value={this.state.password}
                      autoComplete="on"
                      onChange={this.handleChange('password')}
                      margin="normal"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="button"
                      disabled={!this.state.username === '' && this.state.password === ''}
                      style={{ marginTop: '30px', width: '100%' }}
                      type="submit"
                    >
                      Вход в систему
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>

        <Dialog
          width={300}
          onClose={this.handleCloseInfoDialog.bind(this)}
          aria-labelledby="simple-dialog-title"
          open={this.state.isInfoDialogOpen}>
          <DialogTitle id="simple-dialog-title">
            <Grid container>
              <Grid item xs={6}>
                Ошибка
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <IconButton
                  aria-label="Close"
                  onClick={this.handleCloseInfoDialog.bind(this)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <div style={{ padding: '10px' }}>
            <Grid>
              <Grid item xs={12}>
                {this.state.authErrorText}
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default SignIn;